<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5">
                <el-input type="text" size="medium" v-model="form.name" class=" mt-3"
                          placeholder="Tên, SĐT khách hàng" autocomplete="false"></el-input>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-select v-model="form.level" clearable class="w-100 mt-3" placeholder="Chọn level">
                  <el-option
                      v-for="item in optionLevel"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-select v-model="form.dieu_kien_tai_chinh" clearable class="w-100 mt-3" placeholder="Tài chính">
                  <el-option
                      v-for="item in optionFinance"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-date-picker class="w-100 mt-3"
                                v-model="form.ngay_hen"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="Ngày hẹn">
                </el-date-picker>
              </el-col>
              <el-col v-if="isGDKV" :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                <el-select clearable v-model="form.branch_id" filterable class="w-100 mt-3"
                           placeholder="Chọn văn phòng để xem" @change="handleChangeBranch">
                  <el-option
                      v-for="item in branches"
                      :key="item.id"
                      :label="item.name"
                      placeholder="Chọn cơ sở"
                      :value="item.id">
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="3">
                <tree-select class="w-100 mt-3"
                             @input="chooseSale"
                             :show-count="true"
                             search-nested
                             v-model="form.sale"
                             placeholder="Chọn nhân sự muốn xem..."
                             :options="options"
                             :normalizer="normalizer"
                >
                  <!--eslint-disable-->
                  <label slot="option-label"
                         slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
                         :class="labelClassName">
                    <img :src="getImageHierarchy(node.raw.profile.account_type.id)" alt="">
                    <span> {{
                        node.label
                      }} </span>
                    <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                  </label>
                </tree-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="3">
                <el-select v-model="form.view_as" clearable class="w-100 mt-3" placeholder="Chọn tư cách để xem">
                  <el-option
                      v-for="item in watchAs"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>

              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading" class="btn btn-primary w-100 mt-3" @click="submit"><i
                    v-bind:class="[loading ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21">
                <div class="mb-5 mt-5">
                  <h4>Danh sách khách hàng ({{ paging.total }})</h4>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3">
                <router-link class="btn btn-primary font-size-3 w-100 mt-3" :to="{ name: 'customer-create' }">
                  <i class="el-icon-plus"></i> Thêm khách hàng
                </router-link>
              </el-col>
            </el-row>

            <!-- datatable start -->
            <div class="table-responsive-lg b-table-sticky-column table-responsive">
              <table class="table table-hover table-striped table-bordered table-customer">
                <tr>
                  <th>#</th>
                  <th>Họ và tên</th>
                  <th style="width: 100px">Nguồn khách hàng</th>
                  <th style="width: 100px">Phím nóng</th>
                  <th style="min-width: 100px;">Level</th>
                  <th>Lịch hẹn</th>
                  <th>Trung tâm hẹn</th>
                  <th>Chi nhánh hẹn</th>
                  <th>Người tư vấn</th>
                  <th>Mục tiêu</th>
                  <th>Khách hàng của</th>
                  <th style="width: 150px">Giải pháp mời hẹn</th>
                  <th style="width: 150px">Thời gian chăm sóc</th>
                  <th style="width: 150px;">Hành động</th>
                </tr>
                <tbody>
                <template v-for="(customer, key) in tableData">
                  <tr v-if="!checkTranhChap(customer)">
                    <td> {{ 25 * (paging.current_page ? paging.current_page - 1 : 0) + (key + 1) }}</td>
                    <td>{{ customer.name }}</td>
                    <td>{{ customer.nguon }}</td>
                    <td>{{ customer.phim_nong }}</td>
                    <td>
                      <div class="cell" :style="{'background-color' : customer.level_color }">
                        <p class="customer-level" v-html="customer.level"></p>
                      </div>
                    </td>
                    <td>
                      <p v-if="customer.level_check !== '2E'">{{ customer.ngay_hen }}</p>
                      <p v-else>{{ customer.ngay_hen_dong_tien }}</p>
                    </td>
                    <td>
                      <p v-if="customer.level_check !== '2E'">{{ customer.trung_tam_hen }}</p>
                      <p v-else>{{ customer.center_contract }}</p>
                    </td>
                    <td>
                      <p v-if="customer.level_check !== '2E'">{{ customer.chi_nhanh_hen }}</p>
                      <p v-else>{{ customer.branch_contract }}</p>
                    </td>
                    <td>{{ customer.tu_van_vien }}</td>
                    <td>{{ customer.muc_tieu }}</td>
                    <td>
                      <p>{{ customer.create_user }}</p>
                      <p>{{ customer.parent_sale }}</p>
                    </td>
                    <td>{{ customer.giai_phap_moi_hen }}</td>
                    <td>{{ customer.thoi_han_cham_soc }}</td>
                    <td>
                      <el-row class="action-table" style="text-align: center">
                        <el-tooltip content="Ca test đầu vào" placement="top"
                                    v-if="checkShowBtnDetailTest(customer.test_input_schedule)">
                          <button class="btn btn-warning btn-sm btn-icon waves-effect waves-themed mr-2" @click="handleDetailTestInput(customer)">
                            <i class="fal fa-book"></i>
                          </button>
                        </el-tooltip>
                        <el-tooltip v-else  content="Đặt lịch test đầu vào" placement="top" >
                          <a class="btn btn-success btn-sm btn-icon waves-effect waves-themed"
                             @click="selectCustomer(customer)">
                            <i class="el-icon-date"></i>
                          </a>
                        </el-tooltip>
<!--                        && customer.created_user === currentUser.id-->
                        <template v-if="customer.level_int >= 4 ">
                          <el-tooltip content="Xem thông tin khách hàng" placement="top">
                          <router-link :to="{ name: 'customer-view', params: {id: customer.user_id},query: {customer_id:customer?.bestCustomers[0]?.id }}"
                                       class="btn btn-info btn-sm btn-icon waves-effect waves-themed">
                            <i class="fa fa-eye"></i>
                          </router-link>
                        </el-tooltip>
                        </template>

                        <template v-if="customer.level_int < 3 && customer.created_user === currentUser.id">
                          <el-tooltip :content="customer.ngay_hen ? 'Đổi lịch tư vấn' : 'Đặt lịch tư vấn'"
                                      placement="top">
                            <a @click="handleDatLichTuVan(customer)"
                               class="btn btn-info btn-sm btn-icon waves-effect waves-themed">
                              <i class="fal fa-calendar"></i>
                            </a>
                          </el-tooltip>
                        </template>
                        <el-tooltip content="Sửa thông tin khách hàng" placement="top" v-if="customer.level_int < 4 && customer.created_user === currentUser.id">
                          <router-link :to="{ name: 'customer-edit', params: {id: customer.id}}"
                                       class="btn btn-primary btn-sm btn-icon waves-effect waves-themed">
                            <i class="fal fa-edit"></i>
                          </router-link>
                        </el-tooltip>
                      </el-row>
                    </td>
                  </tr>
                  <tr v-else class="text-center"
                      style="height: 80px; text-align: center;align-items: center; background-color: darkgray">
                    <td> {{ 25 * (paging.current_page ? paging.current_page - 1 : 0) + (key + 1) }}</td>
                    <td>{{ customer.name }}</td>
                    <td colspan="12" style="vertical-align: middle; ">Data là khách hàng của sale khác</td>
                  </tr>
                </template>

                </tbody>
              </table>
            </div>
            <div class="edutalk-paging">
              <div class="block" v-if="paging.total > 25">
                <!--eslint-disable-->
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="paging.current_page"
                    :page-size="25"
                    layout="prev, pager, next"
                    :total="paging.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogFormVisible">
      <el-row :gutter="10" class="pr-2 pl-2">
        <dat-lich-tu-van v-if="dialogFormVisible" @completed="completedUpdate" @close="closePopup"
                         :customer-id="customer_id" :customer-name="customer_name" :customer-phone="customer_phone"
                         :ngay-hen-old="ngay_hen"
                         :displayTestInput="displayTestInput"></dat-lich-tu-van>
      </el-row>
    </el-dialog>
    <el-dialog title="Thông báo" class="responsive" :visible.sync="dialogTestScheduleCompleteVisible">
      <el-row :gutter="10" class="pr-2 pl-2">
        <test-schedule-complete v-if="dialogTestScheduleCompleteVisible"
                                @completed="closePopupTestSchedule"></test-schedule-complete>
      </el-row>
    </el-dialog>
    <el-dialog title="Đặt lịch test đầu vào" class="responsive" :visible.sync="openScheduleTestInput" :destroy-on-close="true" :before-close="resetModal()"
               :close-on-click-modal="false"
    :show-close="false" >

      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form appointment-form" @submit.prevent="handleSubmit(createScheduleTestInput)" autocomplete="off">
          <el-row :gutter="10" class="pr-2 pl-2">
            <option-create-test-input v-if="statusCreateTestInput === STATUS_CHOOSE_OPTION"  @cancel="openScheduleTestInput = false" @confirmOption="confirmOption"></option-create-test-input>
            <reschedule-test-input
                v-if="statusCreateTestInput === STATUS_CREATE"
                :showTestInput="true"
                                   @infoTestInput="handleInfoTestInput"
            >
            </reschedule-test-input>
            <LoadingCreateTestInput v-if="statusCreateTestInput === STATUS_PENDING" :idTaskCreating = "idTaskCreating" @statusCreateTestInput="handleStatusCreateTestInput"
            :countTime="countTime"></LoadingCreateTestInput>
            <succes-create-input v-if="statusCreateTestInput === STATUS_TO_DO" @closeModal="openScheduleTestInput = false" @reloadPage=" querySever();" :idCustomer="idCustomer" :successCreateData="successCreateData"></succes-create-input>
            <cancel-create-input v-if="statusCreateTestInput === STATUS_FAlURE" @closeModal="openScheduleTestInput = false"></cancel-create-input>
            <overload-create-test-input v-if="statusCreateTestInput === STATUS_OVER_LOADING" @closeModal="openScheduleTestInput = false"></overload-create-test-input>
          </el-row>
          <div class="form-row" v-if="statusCreateTestInput === STATUS_CREATE">
            <div class="col-md-12 mb-3">
              <button class="btn btn-primary float-right mt-3 ml-3" :disabled="isDisable">
                Xác nhận
              </button>
              <button type="button" class="btn btn-secondary float-right mt-3 ml-3" @click="openScheduleTestInput = false;">
                Hủy bỏ
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </el-dialog>
    <el-dialog title="Đặt lịch test đầu vào" class="responsive" :visible.sync="openChoseOption"  >

    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {BRANCH_LIST, HIERARCHY} from "@/core/services/store/service.module";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {mapGetters} from "vuex";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {CHECK_BEFORE_CONSULT_SCHEDULE, CUSTOMER_LIST} from "@/core/services/store/customer/customer.module";
import DatLichTuVan from "@/views/pages/customer/dat-lich-tu-van";
import Swal from 'sweetalert2';
import TestScheduleComplete from "@/views/pages/customer/test-schedule-complete";
import RescheduleTestInput from "@/views/pages/customer/reschedule-test-input.vue";
import {CREATE_TEST_INPUT_SCHEDULE_CUSTOMER} from "@/core/services/store/customer/testInput.module";
import LoadingCreateTestInput from "./components/LoadingCreateTestInput.vue";
import SuccesCreateInput from "./components/SuccesCreateInput.vue";
import CancelCreateInput from "./components/CancelCreateInput.vue";
import OverloadCreateTestInput from "./components/OverloadCreateTestInput.vue";
import {GET_SPEAKING_BY_TEST_INPUT} from "@/core/services/store/customer/testInput.module";
import {
  STATUS_CREATE,
  STATUS_FAlURE,
  STATUS_OVER_LOADING,
  STATUS_PENDING,
  STATUS_TO_DO
} from "@/core/config/testInput/testInputOption";
import router from "../../../router";
import ParseInt from "lodash-es/parseInt";
import {
  STATUS_CANCEL,
  STATUS_CHOOSE_OPTION, STATUS_NOT_ACTIVE,
  STATUS_ONLY_GENERAL,
  STATUS_SUCCESS
} from "../../../core/config/testInput/testInputOption";
import OptionCreateTestInput from "./components/OptionCreateTestInput.vue";
import {STORE_ONLY_GENERAL} from "../../../core/services/store/customer/testInput.module";

const _ = deepdash(lodash);

export default {
  name: "ListCustomer",
  components: {
    OptionCreateTestInput,
    OverloadCreateTestInput,
    CancelCreateInput,
    SuccesCreateInput,
    LoadingCreateTestInput,
    RescheduleTestInput,
    TestScheduleComplete,
    DatLichTuVan
  },
  data() {
    return {
      paging: {
        current_page: 1,
        per_page: 50,
        total: 0,
      },
      dialogImageUrl: '',
      loading: false,
      currentPage: 1,
      selectDate: '',
      defaultValue: '',
      isGDKV: false,
      imageHierarchy: {
        '5': `${process.env.VUE_APP_BASE_URL}media/hierarchy/gdvp.png`,
        '1': `${process.env.VUE_APP_BASE_URL}media/hierarchy/sl.png`,
        '2': `${process.env.VUE_APP_BASE_URL}media/hierarchy/se.png`,
        '3': `${process.env.VUE_APP_BASE_URL}media/hierarchy/s.png`,
        '4': `${process.env.VUE_APP_BASE_URL}media/hierarchy/si.png`
      },
      total: {tong: 0, so_luot: 0},
      options: [],
      form: {
        name: '',
        selectDate: '',
        view_as: '',
        branch_id: '',
        sale: null,
        level: '',
        dieu_kien_tai_chinh: '',
        ngay_hen: '',
      },
      branches: [],
      watchAs: [
        {
          id: 1,
          value: 'Đội kinh doanh',
          label: 'Đội'
        },
        {
          id: 2,
          value: 'Cá nhân',
          label: 'Cá nhân'
        }
      ],
      optionLevel: [
        {id: '1A', value: '1A - Nhu cầu'},
        {id: '1C', value: '1C - Trống'},
        {id: '2A', value: '2A - Có lịch hẹn'},
        {id: '2B', value: '2B - Delay hẹn'},
        {id: '2C', value: '2C - Hủy hẹn'},
        {id: '2E', value: '2E - Đặt lịch đóng tiền'},
        {id: '3', value: '3 - Tư vấn'},
        {id: '3A', value: '3A - Đăng ký'},
        {id: '3B', value: '3B - Khách hàng đến'},
        {id: '3C', value: '3C - Không đăng ký'},
        {id: '3E', value: '3E - Phân vân'},
        {id: '4A', value: '4A - Đã đóng trên 1200k'},
        {id: '4B', value: '4B - Đóng tiền dưới 1200k'},
        {id: '4D', value: '4D - Rút tiền'},
        {id: '5A', value: '5A - Đã đăng ký khóa combo'},
        {id: '5B', value: '5B - Đã đăng ký khóa lẻ'},
        {id: '5D', value: '5D - Hủy đăng ký'},
        {id: '6', value: '6 - Đang học'},
        {id: '7', value: '7 - Đợi lớp'},
        {id: '8', value: '8 - Ngừng học'},
      ],
      optionFinance: [
        {id: 0, value: 'Từ 0 đến dưới 5 triệu'},
        {id: 1, value: 'Từ 5 đến dưới 10 triệu'},
        {id: 2, value: 'Từ 10 đến dưới 20 triệu'},
        {id: 3, value: 'Từ 20 đến dưới 30 triệu'},
        {id: 4, value: 'Từ 30 đến dưới 40 triệu'},
        {id: 5, value: 'Từ 40 đến dưới 50 triệu'},
        {id: 6, value: 'Từ 50 triệu trở lên'},
      ],
      tableData: [],
      dialog: {
        title: ''
      },
      customer_id: null,
      ngay_hen: null,
      customer_name: null,
      customer_phone: null,
      dialogFormVisible: false,
      dialogTestScheduleCompleteVisible: false,
      openScheduleTestInput: false,
      testInput: null,
      idCustomerSelected: null,
      isDisable: false,
      displayTestInput : true,
      statusCreateTestInput: STATUS_CHOOSE_OPTION,
      idTaskCreating : null,
      STATUS_TO_DO:STATUS_TO_DO,
      STATUS_PENDING:STATUS_PENDING,
      STATUS_FAlURE:STATUS_FAlURE,
      STATUS_OVER_LOADING:STATUS_OVER_LOADING,
      STATUS_CREATE:STATUS_CREATE,
      idCustomer : null,
      countTime : 60,
      openChoseOption: false,
      STATUS_CHOOSE_OPTION: STATUS_CHOOSE_OPTION,
      selectedCustomer: {},
      STATUS_ONLY_GENERAL: STATUS_ONLY_GENERAL,
      successCreateData:{}
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách", route: "customer-list"},
      {title: "Khách hàng", icon: 'fas fa-users'}
    ]);
    if (this.currentUser.area > 0 && this.currentUser.account_type === 'GĐKV') {
      this.isGDKV = true;
      this.getCenter();
    } else {
      if (_.indexOf(['TPNS', 'TPOnline', 'TPTT'], this.currentUser.account_type) > -1) {
        this.watchAs.unshift({
          id: 0,
          value: 'TPhòng/Giám đốc',
          label: 'TP/GĐ'
        });
      }
      this.statusCreateTestInput = this.isPilot? this.STATUS_CHOOSE_OPTION:this.STATUS_CREATE
      this.form.view_as = null;
      this.querySever();
      this.handleChangeBranch()
    }
    this.checkShowBtnDetailTest()
  },
  methods: {
    checkShowBtnDetailTest(listTest) {
      if (listTest?.length > 0) {
        return listTest?.find(item => item?.statusSpeaking === 3) == null
      }
      return false;
    },
    checkTranhChap(customer) {
      return (customer.created_user === this.currentUser.id && customer.ma_gioi_thieu - 100000 !== this.currentUser.id && customer.ma_gioi_thieu > 0)
          || (customer.created_user !== this.currentUser.id && customer.ma_gioi_thieu - 100000 !== customer.created_user);
    },
    normalizer: function (node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.querySever({page: val});
    },
    indexMethod(index) {
      return 25 * (this.currentPage - 1) + (index + 1);
    },
    submit() {
      this.querySever();
    },
    chooseSale() {
      if (!this.form.sale) {
        this.form.view_as = null
      }
    },
    getImageHierarchy(account_type) {
      return this.imageHierarchy[account_type];
    },
    getCenter() {
      this.$store.dispatch(BRANCH_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.branches = response.data;
              this.form.branch_id = response?.data?.length > 0 ? response?.data[0].id : 0;
              this.querySever({branch_id: this.form.branch_id});
              this.handleChangeBranch()
            }
          })

    },
    handleChangeBranch() {
      if (this.form.branch_id > 0) {
        this.$store.dispatch(HIERARCHY, {branch_id: this.form.branch_id})
            .then((response) => {
              this.form.sale = null;
              this.options = response.data;
            })
      } else {
        this.form.sale = null;
        this.form.view_as = null;
        this.$store.dispatch(HIERARCHY, {branch_id: this.currentUser.profile.chi_nhanh_id})
            .then((response) => {
              this.form.sale = null;
              this.options = response.data;
            })
      }
    },

    querySever(customProperties) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.loading = true;
      let params = this.mergeParams(customProperties);
      this.currentPage = 1;
      this.$store.dispatch(CUSTOMER_LIST, params)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.tableData = response.data;
              this.total = response.data.total;
              this.paging = response.pagination
            }
            this.loading = false;
          }).finally(()=>{
        loading.close();
      })
    },
    mergeParams(customProperties) {
      let params = {
        page: this.paging.current_page,
        per_page: this.paging.per_page,
        view_as: this.form.view_as,
      };
      if (this.form.ngay_hen) {
        params = _.merge(params, {ngay_hen: this.form.ngay_hen})
      }
      if (this.form.level) {
        params = _.merge(params, {level: this.form.level})
      }
      if (this.form.name) {
        params = _.merge(params, {search: this.form.name})
      }
      if (this.form.dieu_kien_tai_chinh) {
        params = _.merge(params, {dieu_kien_tai_chinh: this.form.dieu_kien_tai_chinh})
      }
      if (this.form.branch_id) {
        params = _.merge(params, {branch_id: this.form.branch_id})
      }
      if (this.form.sale) {
        params = _.merge(params, {sale: this.form.sale})
      }
      params = _.merge(params, customProperties);
      return params;
    },
    closePopup() {
      this.dialogFormVisible = false
    },
    handleDatLichTuVan(item) {
      if (item.nguon === '' || item.phim_nong === '') {
        Swal.fire('Thông báo', 'Khách hàng cần có đầy đủ nguồn và phím nóng mới có thể đặt lịch!', 'error');
        return false;
      } else {
        this.displayTestInput = item.test_input_schedule.length <= 0;
        this.dialog.title = item.ngay_hen ? 'Đổi lịch tư vấn' : 'Đặt lịch tư vấn'
        this.customer_id = item.id
        this.ngay_hen = item.ngay_hen_old
        this.customer_phone = item.phone
        this.customer_name = item.name
        this.dialogFormVisible = true
        this.isUpdate = true
      }
      // else {
      //   this.$store.dispatch(CHECK_BEFORE_CONSULT_SCHEDULE, {
      //     'id': item.id,
      //     'phone': item.phone
      //   })
      //       .then((response) => {
      //         if (response.status === 422) {
      //           this.$notify({
      //             title: 'Warning',
      //             message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
      //             type: 'warning'
      //           });
      //         } else {
      //           if (response.data.check) {
      //             Swal.fire('Thông báo', 'Đã có sale khác đặt lịch, bạn không thể đặt lịch cho khách hàng nữa!', 'error');
      //             return false;
      //           } else {
      //             this.dialog.title = 'Đặt lịch tư vấn';
      //             this.customer_id = item.id;
      //             this.ngay_hen = item.ngay_hen_old;
      //             this.dialogFormVisible = true;
      //             this.isUpdate = true;
      //           }
      //         }
      //       });
      // }
    },
    handleDatLichDongTien(item) {
      this.$store.dispatch(CHECK_BEFORE_CONSULT_SCHEDULE, {
        'id': item.id,
        'phone': item.phone
      })
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              if (response.data.check) {
                Swal.fire('Thông báo', 'Đã có sale khác đặt lịch, bạn không thể đặt lịch cho khách hàng nữa!', 'error');
                return false;
              } else {
                this.$router.push({name: "advise-center", query: {customer_id: item?.id, payment: 1}});
              }
            }
          });
    },
    completedUpdate(item) {
      if(item) {
        let index_customer = this.tableData.findIndex(i => i.id === item.id);
        let data = this.tableData[index_customer];
        _.merge(data, item);

        this.dialogFormVisible = false
        if (item.check_test_input) {
          this.dialogTestScheduleCompleteVisible = true
        } else {
          if (this.dialog.title == 'Đổi lịch tư vấn') {
            this.$message({type: 'success', message: `Đổi lịch tư vấn thành công`, showClose: true});
          } else {
            this.$message({type: 'success', message: `Đặt lịch tư vấn thành công`, showClose: true});
          }
        }
      }
      this.dialogFormVisible = false
      this.querySever();
      
    },
    closePopupTestSchedule() {
      this.dialogTestScheduleCompleteVisible = false
    },
    handleInfoTestInput(item) {
      this.testInput = item;
      this.testInput.customer_id = this.idCustomerSelected
    },
    createScheduleTestInput() {
      if(this.statusCreateTestInput === STATUS_CHOOSE_OPTION){
        return
      }
      let payload = this.testInput;
      this.isDisable = true;
      this.$store.dispatch(CREATE_TEST_INPUT_SCHEDULE_CUSTOMER, payload).then((data) => {
        setTimeout(()=>{
          this.statusCreateTestInput = STATUS_PENDING;
        })
        this.idTaskCreating = data?.data?.id;
        // this.openScheduleTestInput = false;
      }).catch((error) => {
        this.$message({type: 'error', message: `${error.data.message}`, showClose: true});
        this.testInput.time = null;
        this.testInput.ngay_hen = null;
      }).finally(()=>{
        // this.querySever();
        this.isDisable = false;
      })
    },
    selectCustomer(customer) {
      this.openScheduleTestInput = true;
      this.idCustomerSelected = customer.id;
      this.selectedCustomer = customer;
    },
    handleStatusCreateTestInput(status,customerId,dataResponse){
      this.idCustomer = customerId;
      this.successCreateData = dataResponse;
      setTimeout(()=>{
        this.statusCreateTestInput = status;
      })

    },
    handleDetailTestInput(customer){
      const statusCustomer = customer?.test_input_schedule[0].status
      if(+statusCustomer === this.STATUS_ONLY_GENERAL){
        router.push({ name: 'customer-test-input-schedule', params: {id: customer.id}})
        return
      }
      this.$store.dispatch(GET_SPEAKING_BY_TEST_INPUT,{id: customer?.test_input_schedule[0]?.id}).then((data)=>{
        if(data.data.status === null || data.data.status === undefined){
          return;
        }
        if( [this.STATUS_TO_DO, STATUS_SUCCESS, STATUS_CANCEL, STATUS_NOT_ACTIVE].includes(+data.data.status)){
          router.push({ name: 'customer-test-input-schedule', params: {id: customer.id}})
          return
        }
        if(+data.data.status == STATUS_PENDING ){
         let secondTimeCreate =  (new Date(data?.data?.created_at).getTime())/1000;
         let  secondCurrent = (new Date().getTime())/1000;
          let TimeRemaining = 60 - (secondCurrent - secondTimeCreate)
          if(+TimeRemaining > 0 ){
            this.countTime = Math.max(TimeRemaining, 60);
            setTimeout(()=>{
              this.statusCreateTestInput = STATUS_PENDING;
            })
            this.idTaskCreating = data?.data?.id;
            return;
          }
          this.selectCustomer(customer)
          return
        }
        this.selectCustomer(customer)
      }).catch(()=>{

      }).finally(()=>{

      })
      //
    },
    resetModal(){
      this.statusCreateTestInput = this.isPilot? this.STATUS_CHOOSE_OPTION:this.STATUS_CREATE
      this.countTime = 60
    },
    confirmOption(isOnlyGeneral,centerId = undefined){
      if(isOnlyGeneral){
        let payload = {
          name: this.selectedCustomer?.name,
          phone: this.selectedCustomer?.phone,
          customer_id: this.selectedCustomer?.id,
          center_id: centerId
        }
        this.$store.dispatch(STORE_ONLY_GENERAL,payload).then((data)=>{
          router.push({ name: 'customer-test-input-schedule', params: {id: this.selectedCustomer.id}})
        })
        return
      }
      this.statusCreateTestInput = this.STATUS_CREATE;
    }
  },
  computed: {
    ...mapGetters(['currentUser','isPilot'])
  }
}
</script>
