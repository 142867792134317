<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="form appointment-form" @submit.prevent="handleSubmit(submit)" autocomplete="off">
      <div class="form-row">
        <label class="form-label change-color">Thông tin khách hàng</label>
      </div>
      <div class="form-row">
        Họ và tên: {{ customerName }}
      </div>
      <div class="form-row mt-2">
        Số điện thoại: {{ customerPhone }}
      </div>
      <div class="form-row mt-3">
        <label class="form-label change-color">Lịch tư vấn</label>
      </div>
      <div class="form-row mt-1">
        <div class="col-md-6 mb-2">
          Trung tâm lên tư vấn <span class="text-danger">(*)</span>
          <ValidationProvider vid="center_id" name="Trung tâm lên tư vấn" rules="required" v-slot="{ errors,classes }">
            <el-select v-model="appointment.center_id" filterable class="w-100" placeholder="Chọn trung tâm"
                       @change="handleCenterChange">
              <el-option
                  v-for="item in centers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :class="classes"
              >
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          Cơ sở lên tư vấn <span class="text-danger">(*)</span>
          <ValidationProvider vid="branch_id" name="Cơ sở lên tư vấn" rules="required"
                              v-slot="{ errors,classes }">
            <el-select v-model="appointment.branch_id" filterable class="w-100" placeholder="Chọn cơ sở"
                       @change="handleBranchChange">
              <el-option
                  v-for="item in branches"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :class="classes"
              >
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-row" v-if="check_show">
        <div class="col-md-6 mb-2">
          Hình thức tư vấn <span class="text-danger">(*)</span>
          <ValidationProvider vid="center_id" name="Hình thức tư vấn" rules="required" v-slot="{ errors,classes }">
            <el-select v-model="appointment.hinh_thuc_tu_van" filterable class="w-100" placeholder="Chọn hình thức">
              <el-option
                  v-for="item in hinhThucTuVan"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :class="classes"
              >
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          Thời gian tư vấn <span class="text-danger">(*)</span>
          <ValidationProvider vid="ngay_hen" name="Thời gian" rules="required" v-slot="{ errors,classes }">
            <edutalk-date-picker
                :editable="false"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                :arrow-control="true"
                v-model="appointment.ngay_hen"
                placeholder="Thời gian"
                :picker-options="pickerOptions"
                timeArrowControl
                type="datetime"
                :class="classes"
                :clearable="false"
                @change="getLichTrucTuVan()"
            >
            </edutalk-date-picker>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                {{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-row mt-2 mb-1" v-if="check_show">
        <label>Chọn tư vấn viên</label>
      </div>
      <div class="form-row border rounded" v-if="check_show">
        <div class="col-md-6 mt-2 pl-3">
          <span>Tư vấn viên trong đội</span>
          <div class="mb-3 mt-2" v-if="check_show && doiTuVanVien.length > 0">
            <el-radio-group v-model="appointment.tvv_doi" @change="handleSelectcounselors('tvv-doi')">
              <div class="el-row" v-for="item in doiTuVanVien" :key="item.id">
                <el-radio name="doi_tu_van" :value="item.id" :label="item.id">
                  {{
                    (item.name ? item.name : (item.first_name + " " + item.last_name)) + " - " + (item.phone ? item.phone : "")
                  }}
                </el-radio>
              </div>
            </el-radio-group>
          </div>
          <div class="mb-3 mt-2 font-italic" v-if="check_show && doiTuVanVien.length == 0">
            Các cấp trên hiện tại của bạn không phải là tư vấn viên, vui lòng đặt lịch với tư vấn viên văn phòng!
          </div>
        </div>
        <div class="col-md-6 mt-2">
          Tư vấn viên trực tại cơ sở
          <div class="mb-3 mt-2" v-if="check_show && events.length > 0">
            <el-radio-group v-model="appointment.event_id" @change="handleSelectcounselors('tvv-van-phong')">
              <div class="el-row" v-for="item in events" :key="item.id">
                <el-radio name="event_id" :value="item.id" :label="item.id">
                  {{ item.nameUser + " - " + item.phoneUser }}
                </el-radio>
              </div>
            </el-radio-group>
          </div>
          <div class="mb-3 mt-2 font-italic" v-if="check_show && events.length == 0">
            Hiện tại không có tư vấn viên nào đang trực
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3" v-if="check_show">
          <button :disabled="loading.save" class="btn btn-primary float-right mt-3 ml-3 dat-lich-tvv">
            <i v-if="loading.save" class="el-icon-loading"></i>
            Đặt lịch
          </button>
          <a @click="setAlarm = 'close' && close()"
             class="btn btn-secondary float-right mt-3 ml-3 dat-lich-tvv text-white cursor-pointer">
            Hủy bỏ
          </a>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<style>
.change-color {
  color: #472f92;
  font-size: 15px;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #472f92;
  background: #472f92;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #472f92;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #472f92;
  border-color: #472f92;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #472f92;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #472F92;
}

.el-radio__inner:hover {
  border-color: #472F92;
}

.el-checkbox__inner:hover {
  border-color: #472F92;
}

.el-select .el-input__inner {
  padding-right: 42px !important;
}
</style>
<script>
import {mapGetters} from "vuex";
import {
  CENTER_LIST,
  BRANCH_LIST,
  LICH_TRUC_TU_VAN,
  DOI_TU_VAN_VIEN, DAT_LICH_TU_VAN, DAT_LICH_DOI
} from "../../../core/services/store/customer/customer.module";
import Swal from 'sweetalert2';
import {TIME_SELECT_OPTION} from "@/core/config/customer/selectTimeOption";
import TestInput from "@/views/pages/customer/test-input";
import {TOEIC} from "../../../core/config/center/centerOption";

export default {
  components: {TestInput},
  props: {
    customerId: {
      default: null
    },
    ngayHenOld: {
      default: null
    },
    data: Object,
    customerName: {
      default: null
    },
    customerPhone: {
      default: null
    },
    displayTestInput:{
      default: true
    }
  },
  data() {
    return {
      check_show: false,
      startTime: '00:00:00',
      endTime: '23:59:59',
      defaultDate: null,
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
      loading: {
        save: false,
        loadingSelect: false
      },
      item: {},
      setAlarm: null,
      ngay_hen: null,
      plurality: null,
      account_type: null,
      time_start_work: null,
      parent: null,
      saleOption: [],
      centers: [],
      appointment: {
        hinh_thuc_tu_van: 1,
        tvv_doi: null,
        event_id: null
      },
      branches: [],
      events: [],
      doiTuVanVien: [],
      hinhThucTuVan: [
        {id: 0, name: "Tư vấn không qua Edutalk"},
        {id: 1, name: "Tư vấn qua Edutalk"},
      ],
      hinhThucTestDauVao: [
        {id: 0, name: "Trực tiếp tại cơ sở"},
        {id: 1, name: "Online"},
      ],
      checkboxTestInput: false,
      testInput: {},
      optionSelectTime: TIME_SELECT_OPTION,
      showTestInput: false,
      centersTestInput: [],
      branchesTestInput: [],
      showCheckboxTestInput: true,
    }
  },
  methods: {
    disabledDate(date) {
      let tempDate = new Date();
      let currentDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0);
      return date < currentDate;
    },
    close() {
      this.$emit('close')
    },
    submit() {
      if (this.setAlarm == 'close') {
        this.$emit('close', {
          is_close: true
        });
      } else {
        if (this.appointment.event_id == null && this.appointment.tvv_doi == null) {
          Swal.fire('Thông báo', 'Bạn chưa chọn tư vấn viên', 'error')
        } else {
          let checkTestInput = false
          if (this.checkboxTestInput) {
            checkTestInput = true
          }
          this.datLichTuVan(checkTestInput)
        }
      }
    },
    datLichTuVan(checkTestInput) {
			if (this.loading.save){
				return false;
			}
      let params = {
        'center_id': this.appointment.center_id,
        'branch_id': this.appointment.branch_id,
        'ngay_hen': this.appointment.ngay_hen,
        'hinh_thuc_tu_van': this.appointment.hinh_thuc_tu_van,
        'event_id': this.appointment.event_id,
        'tvv_doi': this.appointment.tvv_doi,
        'customer_id': this.customerId,
        'type_exams': this.testInput.type_exams
      }
      if (this.checkboxTestInput) {
        params = _.merge(params, {checkTestInput: true, testInput: this.testInput})
      }
			this.loading.save = true;
      this.$store.dispatch(DAT_LICH_TU_VAN, params)
          .then((response) => {
						this.loading.save = false;
						this.$emit('completed', {
							ngay_hen: response.data.ngay_hen,
							ngay_hen_old: response.data.ngay_hen_old,
							trung_tam_hen: response.data.trung_tam_hen,
							chi_nhanh_hen: response.data.chi_nhanh_hen,
							tu_van_vien: response.data.tu_van_vien,
							id: response.data.id,
							level: response.data.level,
							check_test_input: checkTestInput
						});
          }).catch((error) => {
        this.$message({type: 'error', message: `${error.data.message}`, showClose: true});
        this.$emit('completed');
        this.testInput.time = null;
        this.testInput.ngay_hen = null;
      }).finally(() => {
				setTimeout(() => {
					this.loading.save = false;
				}, 2000); 
			});
    },
    getCenters() {
      this.$store.dispatch(CENTER_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.centers = response.data;
              this.centersTestInput = response.data.filter(center => center.is_edutalk === 1 && center.id !== TOEIC);
            }
          });
    },
    async getBranches() {
      await this.$store.dispatch(BRANCH_LIST, {center_id: this.appointment.center_id})
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.branches = response.data;
              this.appointment.branch_id = response.data[0].id ?? '';
            }
          });
    },
    getLichTrucTuVan() {
      this.$store.dispatch(LICH_TRUC_TU_VAN, {
        'center_id': this.appointment.center_id,
        'branch_id': this.appointment.branch_id,
        'ngay_hen': this.appointment.ngay_hen
      })
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              response.data.map((event) => {
                event.start_time = this.$moment(event.start_time, 'HH:mm:ss').format('HH:mm');
                event.end_time = this.$moment(event.end_time, 'HH:mm:ss').format('HH:mm');
                let time_select = this.$moment(this.appointment.ngay_hen).format('HH:mm');
                event.is_selected = false;
                if (time_select >= event.start_time && time_select <= event.end_time) {
                  event.select = true;
                } else {
                  event.select = false;
                }
              });
              this.events = response.data;
            }
          });
    },
    getDoiTuVanVien() {
      this.$store.dispatch(DOI_TU_VAN_VIEN)
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.doiTuVanVien = response.data;
            }
          });
    },
    selectTvv(id) {
      this.appointment.event_id = id;
      this.events.map((event) => {
        if (event.id == id) {
          event.is_selected = true;
        } else {
          event.is_selected = false;
        }
      });
    },
    unSelectTvv() {
      this.appointment.event_id = ''
      this.events.map((event) => {
        event.is_selected = false;
      });
    },
    async handleCenterChange() {
      this.check_show = true;
      await this.getBranches();
      await this.getLichTrucTuVan();
      await this.getDoiTuVanVien();
    },
    handleBranchChange() {
      this.getLichTrucTuVan();
    },
    checkTestInput() {
      if (!this.checkboxTestInput) {
        this.showTestInput = true
      } else {
        this.showTestInput = false
      }
    },
    handleSelectcounselors(type) {
      if (type == 'tvv-doi') {
        this.appointment.event_id = null
      }
      if (type == 'tvv-van-phong') {
        this.appointment.tvv_doi = null
      }
    },
    handleInfoTestInput(item) {
      this.testInput = item
    }
  },
  mounted() {
    this.getCenters();
    if (this.ngayHenOld) {
      this.appointment.ngay_hen = this.$moment(this.ngayHenOld).format('yyyy-MM-DD HH:mm');
      this.showCheckboxTestInput = false
    } else {
      this.appointment.ngay_hen = this.$moment().format('yyyy-MM-DD HH:mm');
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>
