<template>
  <div>
    <p>Vui lòng chọn đúng nhu cầu kiểm tra đầu vào:</p>
    <div>
      <el-radio v-model="radio1" :label="false">Đặt lịch test speaking với admin test (gồm cả bài test general)
      </el-radio>
    </div>
    <div class="mt-2">
      <el-radio v-model="radio1" :label="true">Chỉ làm bài test general</el-radio>
    </div>
    <div class="mb-2" v-if="radio1">
      <el-select v-model="center_id"
                 filterable class="w-100"
                 placeholder="Chọn trung tâm"
      >
        <el-option
            v-for="item in centersTestInput"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
          <span style="float: left">{{ item.name }}</span>
        </el-option>
      </el-select>
    </div>
    <p class="mt-2" style="color: #FF0000; font-style: italic">Lưu ý: Nguồn lực admin test là có hạn. Vì vậy, chỉ đặt
      lịch test speaking khi chắc chắn lịch hẹn với khách hàng.</p>
    <div class="d-flex justify-content-end mt-6">
      <button type="button" class="btn btn-secondary mr-2" @click="cancel()">
        Hủy bỏ
      </button>
      <button class="btn btn-primary" :disabled="radio1 === undefined || loading || (radio1 && !center_id)" @click="confirmOption()">
        <i v-if="loading" class="el-icon-loading"></i>
        Xác nhận
      </button>
    </div>
  </div>
</template>
<script>
import {TOEIC} from "../../../../core/config/center/centerOption";
import {CENTER_LIST} from "../../../../core/services/store/customer/customer.module";

export default {
  name: "OptionCreateTestInput",
  data() {
    return {
      radio1: undefined,
      loading: false,
      centersTestInput: [],
      center_id: null
    }
  },
  mounted() {
  this.getCenters()
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirmOption() {
      this.loading = true;
      this.$emit('confirmOption', this.radio1,this.center_id)
    },
    getCenters() {
      this.$store.dispatch(CENTER_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.centers = response.data;
              this.centersTestInput = response.data.filter(center => center.is_edutalk === 1 && center.id !== TOEIC);
            }
          });
    },
  }
}
</script>
<style scoped>

</style>