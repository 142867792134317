<template xmlns="http://www.w3.org/1999/html">
    <div class="text-break">
        <p>Bạn đã đặt lịch kiểm tra đầu vào cho khách hàng thành công!</p>
        <p>Bạn có thể chọn nút
            <span class= "font-weight-bold">Ca test đầu vào </span>
            <button class="btn btn-warning btn-sm btn-icon waves-effect waves-themed"><i class="fal fa-book"></i></button>
            tại Danh sách khách hàng để:</p>
        <p>1. Theo dõi tiến độ, kết quả bài test của khách hàng</p>
        <p>2. Gửi link cho khách hàng làm bài trước giúp tiết kiệm thời gian cho buổi test đầu vào.</p>
        <p>3. Đổi lịch, hủy lịch, đặt lại lịch test đầu vào.</p>
        <div class="row">
            <div class="col-md-12 mb-3">
                <button class="btn btn-primary float-right mt-3 ml-3" @click="closeTestScheduleComplete">
                    Đã hiểu
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "test-schedule-complete",
    methods: {
        closeTestScheduleComplete() {
            this.$emit('completed')
        }
    }
}
</script>

<style scoped>

</style>